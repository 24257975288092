<template>
  <article class="media media-comments">
    <div class="media-left">
      <p class="image is-32x32">
        <img
          src="@/assets/images/spacer1_1.gif"
          class="is-round has-shadow"
          :style="{backgroundImage: `url(${$options.filters.getProfileImage(mComment.ProfileImage, mComment.ProfileId, 38)})`}"
        >
      </p>
    </div>
    <div class="media-content">
      <div class="content">
        <div class="has-text-grey postDetailBar is-size-7">
          {{ mComment.CreatedOn | dateTimeStampToDate | longDateFormat }} -
          {{ mComment.CreatedOn | timeFromDateTimeStamp }}
        </div>
        <div class="is-size-7">
          <a @click.stop="showProfileDetail(mComment.ProfileId)"><strong>{{ mComment.ProfileName }},</strong></a> <span :class="{ 'is-deleted': mComment.IsDeleted }">{{ mComment.Text }}</span>
        </div>

        <div>
          <div v-if="showCommentInput">
            <textarea
              class="textarea has-margin-bottom"
              v-model="commentText"
            />
            <a @click="cancelShowCommentInput" class="has-margin-right">Cancel</a>
              <a @click="sendComment(mComment)" class="button is-primary is-small">Send</a>
            </div>
          </div>

        <nav class="level is-mobile">
          <div class="level-left">
            <a
            @click="deleteComment(mComment)"
            class="is-size-7"
            v-if="!mComment.IsDeleted && mComment.CreatedBy === profile.Id"
          >
          <span class="icon is-small">
              <font-awesome-icon :icon="['fas', 'trash-alt']" />
            </span>
            <span>Delete</span>
          </a>
            
            <a
            v-if="isLoggedIn && !showCommentInput && mComment.CreatedBy !== profile.Id"
            @click="setShowCommentInput()"
            class="is-size-7"
          >
            <span class="icon is-small">
              <font-awesome-icon :icon="['fas', 'reply']" />
            </span>
            <span>Reply</span>
          </a>
          </div>
        </nav>

        <comment-item
          v-for="comment in filterComments(mComment.Id)"
          :key="comment.Id"
          :commentObj="comment"
          :comments="mComments"
        ></comment-item>
      </div>
    </div>
  </article>
</template>

<script>
import { mapGetters } from 'vuex'
import comment from '@/providers/comment'

const CommentItem = () => import('@/components/Comments/CommentItem')

export default {
  components: {
    'comment-item': CommentItem
  },

  props: {
    commentObj: null,
    comments: {
      type: Array,
      default: function() {
        return []
      }
    }
  },

  data() {
    return {
      commentText: '',
      mComment: this.$props.commentObj,
      mComments: this.$props.comments,
      showCommentInput: false
    }
  },

  computed: mapGetters({
    isLoggedIn: 'getIsLoggedIn',
    profile: "getProfile"
  }),

  methods: {
    showProfileDetail(profileId) {
      this.$store.commit('setSelectedPublicProfile', profileId)
    },

    filterComments(parentId) {
      return this.mComments.filter(c => c.ParentId === parentId)
    },

    setShowCommentInput(comment) {
      this.showCommentInput = true
    },

    cancelShowCommentInput(comment) {
      this.showCommentInput = false
    },

    sendComment(value) {
      comment.methods
        .replyToComment(value.Id, this.commentText)
        .then(response => {
          //this.mComments.push(response.data)
          this.commentText = ''
          this.showCommentInput = false
        })
    },

    deleteComment(value) {
      comment.methods.deleteComment(value.Id).then(response => {
        value.IsDeleted = true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.is-deleted {
  text-decoration: line-through;
}
</style>